
import { Component, Vue } from 'vue-property-decorator'
import { SubmitInfo } from '@/types/department'
import { ElForm } from 'element-ui/types/form'

@Component
export default class Department extends Vue {
  private departmentList = []
  private roleList = []

  private props = {
    value: 'deptId',
    label: 'deptName',
    children: 'childNode'
  }

  private dialogFormVisible = false
  private title = '新增组织'

  private info: SubmitInfo = {
    deptId: '',
    deptName: '',
    parentDeptId: ''
  }

  private rules = {
    deptName: [{ required: true, message: '请输入组织名称', trigger: ['blur'] }]
  }

  private submitShow = false

  private searchInfo = {
    name: '',
    role: '',
    dept: ''
  }

  private tableData = {
    loading: false,
    tr: [
      {
        label: '姓名',
        prop: 'name',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '角色',
        prop: 'roleName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '联系电话',
        prop: 'mobile',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '所属组织',
        prop: 'deptName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '状态',
        prop: 'isDel',
        minWidth: '80',
        showOverflowTooltip: true
      }
    ],
    data: [{}]
  }

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.departmentListGet()
    this.roleListGet()
    this.personnelGet()
  }

  roleListGet () {
    this.$axios.get(this.$apis.common.getRoleList).then((res) => {
      this.roleList = res || []
    })
  }

  departmentListGet () {
    this.$axios.get(this.$apis.department.departmentList).then((res) => {
      this.departmentList = res || []
    })
  }

  // 部门点击
  departmentClick (data: {dept: string}, node: {level: number}) {
    this.searchInfo.dept = node.level !== 1 ? data.dept : ''
    this.onSearch()
  }

  // 新增组织
  departmentAdd (node: {data: {dept: string}}) {
    this.title = '新增组织'
    this.dialogFormVisible = true
    this.info = {
      deptName: '',
      parentDeptId: node.data.dept
    }
  }

  departmentUpdate (node: { parent: {data: {dept: string}}}, data: {deptName: string; dept: string}) {
    this.title = '编辑组织'
    this.dialogFormVisible = true
    this.info = {
      deptId: data.dept,
      deptName: data.deptName,
      parentDeptId: node.parent.data.dept
    }
  }

  departmentDelete (node: {data: {dept: string}}) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.department.departmentDelete, {
          deptId: node.data.dept
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.departmentListGet()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  submitForm () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.info.deptId
          ? this.$apis.department.departmentUpdate
          : this.$apis.department.departmentAdd
        this.$axios.post(url, this.info).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
          this.departmentListGet()
          this.submitShow = false
          this.dialogFormVisible = false
        })
          .catch(() => {
            this.submitShow = false
          })
      }
    })
  }

  // 人员列表数据请求
  personnelGet () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.personnel.personnelPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.tableData.loading = false
      this.tableData.data = res.list || []
      this.total = res.total || 0
    })
      .catch(() => {
        this.tableData.loading = false
      })
  }

  onSearch () {
    this.page = 1
    this.personnelGet()
  }

  onAdd () {
    this.$router.push({ name: 'personnelAdd' })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'personnelDetail',
      params: {
        id: id
      }
    })
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'personnelUpdate',
      params: {
        id: id
      }
    })
  }

  onDisabled (id: string) {
    this.$axios.post(this.$apis.personnel.personnelDisabled, {
      userId: id
    }).then(() => {
      this.$message({ message: '操作成功', type: 'success' })
      this.personnelGet()
    })
  }

  onDelete (id: string) {
    this.$confirm('是否确认删除该用户？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.personnel.personnelDelete, {
        userId: id
      }).then(() => {
        this.$message.success('操作成功')
        this.personnelGet()
      })
    })
  }
}
